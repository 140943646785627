
function MentalHealth() {
    return (
        <>
            <div class="mental-health-page">
            <h1>Mental Health</h1>

            <p>Author: Carol Hughes is a Macmillan Therapeutic Counsellor at the Royal Orthopaedic Hospital Birmingham.  Carol has many years of experience working with hindquarter amputees.</p>

            <img src="/images/mental-health-john-lennon-quote.png" className="center-image" alt="John Lennon quote" />

            <p>If you've just had a hindquarter amputation, or been advised that you need one, you'll know that everything is not okay, far from it, but neither is it the end.  Though the double whammy of a cancer diagnosis, plus the need for radical life changing surgery, might be making you feel that way.   It is the time of maximum uncertainty and uncertainty is anxiety provoking. You're likely to know people that had or have cancer, but it's unlikely you'll know anyone that has had a hindquarter amputation. All kinds of questions and anxieties may loom large. Will you also need radiotherapy or chemotherapy? What will it mean in daily life? Will you be able to do the things that are important to you? What will you look like?  What about your relationships?  Your clinical team, who will be taking care of you for many years, will be able to answer many, but not all of your questions.  One member of the team will be assigned as your key worker.  They will be your first point of contact if you have any queries and you'll get to know each other especially well over the years to come.</p>

            <p>You'll also be referred to a <a href="http://www.limbless-association.org/index.php/information/amputee/amputation/healthcare-team">Rehabilitation Centre</a> near to where you live.  They'll support you in various ways to maximise your recovery after your surgery.  Many centres like to see you beforehand too if there is sufficient time.</p>

            <p>You'll also be offered invaluable support from Club HQ.  Its members have been where you are now. They are living proof of lives worth living after hindquarter amputation.  Some of its members have returned to work, some drive adapted cars, some play sports and some have formed new relationships.  They are an invaluable source of information, advice and encouragement.</p>

            <img src="/images/mental-health-the-journey.png" class="center-image" alt="The journey" />

            <p>It is only when all treatment is finished, that healing can truly begin.  At this point you may be feeling sick and tired and daunted by the enormity of the challenges facing you.  Some understanding of the process of healing can be really helpful as well as awareness of the support that is also available.</p>

            <p>Clinical psychologist Dr Peter Harvey identifies three stages of <a href="https://www.workingwithcancer.co.uk/wp-content/uploads/2013/03/After-the-treatment-finishes-then-what.pdf">Recuperation, Convalescence and Rehabilitation</a> and Mindfulness Meditation teacher Vidyamala Birch refers to <a href="https://www.breathworks-mindfulness.org.uk/shop/mindfulness-for-health-book">Acceptance Coping and Thriving</a>. They both describe important aspects of recovering your life and a sense of the journey ahead.  It's a journey that can't rushed, though you can facilitate it.  Everyone will move through it in their own way.  It can be useful to keep a journal so that you can look back and see how far you've come.</p>

            <img src="/images/mental-health-acceptance.png" class="center-image" alt="Acceptance" />

            <p>Just as a Hindquarter amputation has biological, psychological and social consequences, so creating the conditions for healing involves all those areas.  Initially you're likely to need peace and quiet to accept what you've been through (rarely available in hospital!)  Acceptance of something you didn't want, even though you agreed it was necessary isn't easy. Sometimes acceptance is confused with resignation or defeat.  Actually, acceptance is the realisation that when we can accept things as they are and let go of the various ways in which we sometimes fight reality; then we can conserve our energy to use more productively and enjoyably.</p>
                
            <p>Peace and quiet, along with supportive relationships, will help shift your physiology from an aroused threat state to the healing state that we experience when we feel safe.  It's also a state conducive to creative problem solving when you begin to think about possibilities for the future.</p>
                
            <p>How much you are truly able to rest and recuperate will depend on things like the level of support available to you, and your feelings about accepting help.  If you have been the main caregiver in your home, you may find accepting help difficult.  As you begin to grow stronger, you and your family/helpers may need to negotiate a pact in which you promise to ask for help if you need it, in return for the space to try doing more things for yourself.  If you are on your own, can you lower your usual standards so that you get sufficient rest between your exertions? There may be friends and neighbours you can call on or volunteer organisations nearby that could make life a bit easier.  GP's surgeries are generally a good source of information. Also, Macmillan has an extensive <a href="https://www.macmillan.org.uk/in-your-area/choose-location.html?location=">network of volunteers</a> who can offer practical help.</p>

            <img src="/images/mental-health-brene-brown-quote.png" class="center-image" alt="Brene Brown quote" />

            <p>Connecting with others is vitally important to your <a href="https://preventdisease.com/news/13/101313_Social-Connection-Found-As-Essential-As-Food-Water.shtml?no_redirect=true">psychological and emotional well-being</a>. However, whilst you're recuperating it's possible to have too much of a good thing.  You may need to limit the number of visitors and/or how long they stay, so that you can benefit from the interactions rather than being drained by them.  Alternatively, if you're feeling isolated, there's a wealth of support available from: Club HQ; Macmillan hosts on-line chat groups: https://community.macmillan.org.uk/cancer_experiences/chat/   The Limbless Association, free to join, also offer a buddy service as well as being a great source of information:  http://www.limbless-association.org/  Or if you're in your 20s, 30s, or 40s you might like to connect up with <a href="https://shinecancersupport.org/">Shine</a> who are a social and support network for that age group.</p>

            <img src="/images/mental-health-mirrors.png" class="center-image" alt="Mirrors" />

            <p>Coming to terms with your altered body begins with being able to look at your wound when it is being dressed.  Touching your body will help to restore normal sensitivity as your brain will be acutely aware of, even hypersensitive to any sensation in that area.  Gently rubbing or lightly tapping with your finger tips will help the brain “turn down the volume”.  You may not see yourself standing full length until you get home.  This in itself can be quite an emotional experience: it's helpful if you can bring softness and gentleness to your experience of seeing your new shape as you become used to it. Perhaps even marvelling at this body of yours which has been through so much and is still able to heal. If you or your partner struggle with this, then ask for help.  Whether you need therapy individually or together, your GP will know what's available in your area.</p>

            <p>Any individual that has had an amputation (or for that matter - been born without a limb) will experience a felt sense of the missing limb.  Many will also experience unpleasant neuropathic pain in the leg that no longer exists.  This is known as Phantom Limb Pain (PLP).  In many ways PLP is a bit of a mystery.  About thirty percent of individuals find that painkillers work well for them, but most find that painkillers work only partially or not at all.</p>

            <p>Interestingly it is known that wearing a prosthetic limb is generally associated with less PLP (some say that their PLP only begins when they take off their prosthetic limb).  However, to master walking with a prosthesis after a hindquarter amputation you'll need to have, or to build up sufficient strength and be committed to the hard work involved in all the fittings and learning to “throw” the leg to achieve walking.  You may also have to convince your rehabilitation centre of your commitment as they know that many give up.</p>

            <img src="/images/mental-health-ramachandran-ted-talk.png" class="center-image" alt="Ramachandran TED talk" />

            <p>Another approach was taken by an American neurologist called Ramachandran who <a href="https://www.ted.com/talks/vs_ramachandran_3_clues_to_understanding_your_brain">noted research</a> that showed an association between PLP and changes in the corresponding part a body map within the brain. He wondered what would happen if feedback from the missing limb was recreated.  So, he devised a <a href="https://www.amputee-coalition.org/resources/mirror-therapy/">Mirror Box</a> to provide visual feedback to the brain that the limb still exists and is moving. Again it doesn't work for everyone, but it often reduces pain and enables people to reposition their phantom limbs if they are stuck in uncomfortable positions. It is possible to achieve similar results with Guided Imagery. Guided Imagery entails imagining movement in great sensory detail. Some universities are researching whether twinning Guided Imagery with Virtual Reality is a useful way to relieve PLP.</p>

            <p>Mindfulness Meditation works is a different way. It doesn't aim to reduce the pain, but rather to widen your awareness from its natural tendency to focus on pain to the exclusion of everything else. Pain, especially when it is severe, can be like an annoying pop-up that goes full screen, blotting out what you wanted to look at.  By learning to broaden awareness despite pain - a space is created for pleasure and relaxation. For many this does have the effect of reducing their pain, for others whilst it doesn't reduce the pain, it does reduce distress that the pain causes.</p>

            <p>These techniques don't work for everyone, but it's certainly worth keeping an open mind and an experimental attitude to these other techniques if painkillers don't work very well for you.</p>

            <img src="/images/mental-health-couple.png" class="center-image" alt="Couple" />
            
            <p>Cancer patients in relationships often struggle to recover a sex life after treatment for cancer.  Those without a partner often fear that they're now unlikely to find one. Sometimes people feel too embarrassed to raise these concerns or feel that their appointments are taken up with things that feel more urgent.  However, physical intimacy with your partner is an important area of life, so don't suffer in silence, find out from your surgeon what they think is possible in your case.  It's worth remembering too that sexuality is so much more than penetrative sex.  Billy Connolly once said the sex is 10% of a good relationship and 90% of a bad one. If you're in an existing sexual relationship or exploring a new one, it is wise to go slowly at first.  Take penetrative sex off the agenda altogether for three months as you both relax and rediscover the all-important intimacy and sensuality of time in bed together.  Should you still decide that specialist input would be helpful, your GP can refer you appropriately or you can access <a href="https://www.macmillan.org.uk/cancer-information-and-support/treatment/coping-with-treatment/your-sex-life">information and advice from Macmillan</a>.</p>

            <img src="/images/mental-health-help-and-support.png" class="center-image" alt="Help and support" />

            <p>You may also have to come to terms with being unable to do your normal work (paid or unpaid). Macmillan have a lot of information about <a href="https://www.macmillan.org.uk/get-involved/campaigns/your-rights-at-work">your rights</a> and how best to approach your employer. And if you have money worries, Macmillan provide advice and help in claiming <a href="https://www.macmillan.org.uk/cancer-information-and-support/impacts-of-cancer/benefits-and-financial-support">means tested and non-means-tested benefits</a>. Local Authorities often provide this service too.  And your Macmillan professional can also apply for a one-off means tested grant on your behalf.</p>

            <p>It may feel as if the losses just keep piling up and as you contemplate and grieve these losses, it is natural to experience low mood or anxiety at times. If you're feeling anxious, it's helpful to notice what thoughts may be going around and around in your head. Separate them into those you can do nothing about (they have to go on a back burner for a while); and those that you can do something towards sorting out.  Perhaps decide what you need to do (in realistic bite-sized tasks), you might even write a list of things to help you remember - then you can tick off each task as you complete it. This helps you measure progress rather than feeling defeated by not having reached the final goal.</p>

            <p>If you're struggling with low mood - keep doing the various things that you know usually relax and lift your mood, even if it feels like a bit of an effort at first. Therapists call this “Fake it til you make it”.   It might be music, sitting in nature, playing with a pet or sharing a joke with someone.  It's a bit like turning a starter motor over - eventually, hopefully, the pleasure kicks in.  Do talk about how you're feeling with family and friends and maybe faith leaders if you have a faith. Your Cancer Nurse Specialist or your GP will also understand how you're feeling.  Sometimes the simple act of acknowledging your feelings is enough. However, if your low mood and/or anxiety are severe and affecting your ability to function or it's simply gone on for months and you feel that you've become stuck, then talk with your GP.  Together you can discuss whether antidepressants or a talking therapy might be helpful and acceptable.  You may want to get in touch with your hospital and find out what psychological support services are available to you.  You might consider a Mindfulness Meditation course which research has shown to reduce anxiety and depression as well as being helpful for pain.</p>

            <p>This initial phase may take many weeks, even months before you have recovered the energy to begin rebuilding your strength again.  Everyone is different.</p>

            <img src="/images/mental-health-comfort-zones.png" class="center-image" alt="Comfort zones" />

            <p>The next phase, convalescence literally means to grow strong.  You'll need the energy you've recovered to work at regaining functionality.  Physiotherapists (or “physioterrorists” as I believe they're called by British Army soldiers) really come into their own here.  You can trust them to know exactly how hard they can safely push you, even though your anxious brain may be telling you otherwise.</p>

            <p>At this stage - it can be tempting either to work too hard or to disengage.  It's perfectly natural to want to do everything on The List in Your Head when you're having a Good Day.  This is a mistake and is almost always followed by a Bad Day where you disappear under the duvet. If boom and bust becomes a habit, the fluctuations tend to become more pronounced and slope ever downwards towards exhaustion and depression.  Learning to pace yourself is vital.  It can be helpful to think of your energy as like having money in the bank. Don't spend it all.  You don't want to overdraw on your reserves if you can help it!  Vidymala Birch advises “Stop before you need to”.  But what if you find it difficult to tune into your body?  Perhaps you always live in your head (the sort of person who has no idea how they acquired that bruise), or maybe you and your body have become estranged from each other since the cancer diagnosis.  Then you may need to trust those around you to let you know when they can see you beginning to tire or learn to spot the emotional signs of fatigue like irritability or tearfulness.  It's normal to feel frustrated when you struggle with things you used to be able to do easily.  So take a breather if you possibly can - bring some softness and kindness to your struggle and only resume when you feel rested. You might even try offering yourself the advice or words of comfort you'd give a loved one in your position.  It's amazing how much kinder we are to others than to ourselves.</p>

            <p>As you gradually rebuild your physical and emotional strength, your confidence will begin to return and you can begin to work towards Thriving.  This will mean something different to each individual. You may be pleasantly surprised at what you ultimately manage to achieve. I know many hindquarter patients doing things they had prematurely grieved as lost to them forever.  You may overhaul your life in quite unexpected but positive ways.  One day you may even find yourself reassuring a newly diagnosed person that a hindquarter amputation is not the end.</p>

            <img src="/images/mental-health-dandelion.png" class="center-image" alt="Dandelion" />
            </div>
        </>
    );
}

export default MentalHealth;
