import React from "react";
import "./Footer.css";

function Footer() {
    return (

        <footer className="footer"><span className="copyright">© 2023 All Rights Reserved</span></footer>

    );
}

export default Footer;